import React from 'react';
import {Dispatch, State} from '../context';
import {
  AssetsByVenueSubDomainQueryVariables,
  ModelAssetConnection,
  ModelScheduleConnection,
} from '../API';
import {generateClient} from 'aws-amplify/api';
import {GraphQLQuery, GraphQLResult} from '@aws-amplify/api';
import {getAuthMode} from './gql';

const client = generateClient();

export type CustomGetVenueQuery = {
  getVenue?: {
    __typename: 'Venue';
    owner?: string | null;
    subDomain: string;
    name: string;
    stripeAccountID?: string | null;
    phoneNumber: string;
    description?: string | null;
    email: string;
    streetAddress?: string | null;
    apt?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    postalCode?: string | null;
    fee: number;
    banner?: string | null;
    cancellationHours?: number | null;
    location: {
      __typename: 'Location';
      latitude?: number | null;
      longitude?: number | null;
      address?: string | null;
    };
    tz: string;
    images: Array<{
      __typename: 'Image';
      url: string;
    }>;
    assets?: ModelAssetConnection | null;
    schedules?: ModelScheduleConnection | null;
    addOns: Array<{
      __typename: 'AddOn';
      name: string;
      description?: string | null;
      price: number;
      singular: boolean;
    }>;
    isPublic: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export const getInitData = (
  venueSubDomain: string,
  isAuthenticated = false,
): ReturnType<typeof client.graphql<GraphQLQuery<CustomGetVenueQuery>>> => {
  const initQuery = /* GraphQL */ `
    query Venue {
      getVenue(subDomain:"${venueSubDomain}") {
        name
        subDomain
        owner
        ${isAuthenticated ? 'stripeAccountID' : ''}
        phoneNumber
        email
        description
        fee
        banner
        cancellationHours
        images {
          url
        }
        location {
          latitude
          longitude
          address
        }
        # tz
        addOns {
          description
          name
          price
          singular
        }
        assets {
          items {
            id
            name
            orderIndex
            isPublic
            schedule {
              items {
                scheduleId
                schedule {
                  id
                  startHour
                  endHour
                  dayOfWeek
                  price
                  priorityIndex
                  blocked
                }
              }
            }
          }
        }
        schedules {
          items {
            id
            startHour
            endHour
            dayOfWeek
            price
            priorityIndex
            assets {
              items {
                asset {
                  id
                  name
                  orderIndex
                }
              }
            }
          }
        }
      }
    }
  `;
  const variables: AssetsByVenueSubDomainQueryVariables = {venueSubDomain};
  return client.graphql({
    query: initQuery,
    variables,
    authMode: getAuthMode(isAuthenticated),
  });

  // const tempSchedule: ModelAssetScheduleConnection = {
  //   items: [
  //     {
  //       __typename: 'AssetSchedule',
  //       id: 'as1',
  //       createdAt: 'lskdjf',
  //       updatedAt: 'lsdkjf',
  //       assetId: 'a1',
  //       scheduleId: 's1',
  //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //       // @ts-ignore
  //       asset: {},
  //       schedule: {
  //         __typename: 'Schedule',
  //         createdAt: 'lskdjf',
  //         updatedAt: 'lsdkjf',
  //         venueSubDomain: 'apex',
  //         id: 's1',
  //         startHour: 9,
  //         endHour: 20,
  //         dayOfWeek: [0, 1, 2, 3, 4, 5, 6],
  //         price: 2000,
  //         priorityIndex: 1,
  //         blocked: false,
  //       },
  //     },
  //   ],
  // };
  // return Promise.resolve<GraphQLResult<GraphQLQuery<CustomGetVenueQuery>>>({
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   data: {
  //     getVenue: {
  //       __typename: "Venue",
  //       name: 'Apex Baadminton',
  //       subDomain: 'apexbadminton',
  //       owner: 'jackson',
  //       stripeAccountID: 'stripeAccountID',
  //       phoneNumber: 'stripeAccountID',
  //       email: 'stripeAccountID',
  //       description: 'stripeAccountID',
  //       fee: 0.05,
  //       banner: 'banner',
  //       cancellationHours: 48,
  //       tz: 'America/Vancouver',
  //       isPublic: true,
  //       images: [],
  //       location: {
  //         __typename: "Location",
  //         latitude: 20,
  //         longitude: 20,
  //       },
  //       createdAt: 'lskdjf',
  //       updatedAt: 'lsdkjf',
  //       addOns: [
  //         {
  //           __typename: "AddOn",
  //           description: 'description',
  //           name: 'description',
  //           price: 500,
  //           singular: true,
  //         },
  //       ],
  //       assets: {
  //         __typename: 'ModelAssetConnection',
  //         items: [
  //           {
  //             __typename: 'Asset',
  //             createdAt: 'lskdjf',
  //             updatedAt: 'lsdkjf',
  //             venueSubDomain: 'apex',
  //             id: 'a1',
  //             addOns: [],
  //             name: 'Court 1',
  //             orderIndex: 1,
  //             isPublic: true,
  //             schedule: tempSchedule,
  //           },
  //           {
  //             __typename: 'Asset',
  //             createdAt: 'lskdjf',
  //             updatedAt: 'lsdkjf',
  //             venueSubDomain: 'apex',
  //             id: 'a2',
  //             addOns: [],
  //             name: 'Court 2',
  //             orderIndex: 2,
  //             isPublic: true,
  //             schedule: tempSchedule,
  //           },
  //           {
  //             __typename: 'Asset',
  //             createdAt: 'lskdjf',
  //             updatedAt: 'lsdkjf',
  //             venueSubDomain: 'apex',
  //             id: 'a3',
  //             addOns: [],
  //             name: 'Court 3',
  //             orderIndex: 3,
  //             isPublic: true,
  //             schedule: tempSchedule,
  //           },
  //           {
  //             __typename: 'Asset',
  //             createdAt: 'lskdjf',
  //             updatedAt: 'lsdkjf',
  //             venueSubDomain: 'apex',
  //             id: 'a4',
  //             addOns: [],
  //             name: 'Court 4',
  //             orderIndex: 4,
  //             isPublic: true,
  //             schedule: tempSchedule,
  //           },
  //         ],
  //       },
  //       schedules: {
  //         __typename: 'ModelScheduleConnection',
  //         items: [
  //           {
  //             __typename: 'Schedule',
  //             createdAt: 'lskdjf',
  //             updatedAt: 'lsdkjf',
  //             venueSubDomain: 'apex',
  //             id: 's1',
  //             startHour: 0,
  //             endHour: 24,
  //             dayOfWeek: [0, 1, 2, 3, 4, 5, 6],
  //             price: 2000,
  //             priorityIndex: 1,
  //             blocked: false,
  //           },
  //         ],
  //       },
  //     },
  //   },
  // });
};

export const getVenueFromResponse = (
  response: Awaited<ReturnType<typeof getInitData>>,
) => {
  return response.data?.getVenue;
};

const useInitData = (subDomain: string, state: State, dispatch: Dispatch) => {
  const {venue, auth} = state;
  const [shouldFetch, setShouldFetch] = React.useState(!venue);

  const startTime = React.useRef(Date.now());

  React.useEffect(() => {
    console.log('isAuthenticated changed', auth.isAuthenticated);
    setShouldFetch(true);
  }, [auth.isAuthenticated]);

  React.useEffect(() => {
    let promise:
      | Promise<GraphQLResult<GraphQLQuery<CustomGetVenueQuery>>>
      | undefined;
    const fetchInitData = async () => {
      const timeElapsed = Date.now() - startTime.current;
      try {
        console.log(
          'Time elapsed before call',
          timeElapsed,
          'isAuthenticated:',
          auth.isAuthenticated,
        );
        promise = getInitData(subDomain, auth.isAuthenticated);
        const response = await promise;
        const body = getVenueFromResponse(response);
        console.log(timeElapsed, body);
        if (body) {
          dispatch({type: 'venue', payload: body});
          dispatch({type: 'assets', payload: body?.assets?.items});
          dispatch({type: 'schedules', payload: body?.schedules?.items});
          // TODO: Set the timezone
          // body?.tz && dayjs.tz.setDefault(body.tz);
          // body?.tz && dispatch({type: 'currentDate', payload: dayjs.tz()});
          setShouldFetch(false);
        }
      } catch (e) {
        console.warn(
          'Error during gql init call with time elapsed',
          timeElapsed,
          e,
        );
      }
    };
    shouldFetch && fetchInitData();
    return () => {
      promise && client.cancel(promise);
    };
  }, [shouldFetch, subDomain, auth.isAuthenticated]);

  return {refetch: () => setShouldFetch(true)};
};

export default useInitData;
