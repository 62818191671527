import {GraphQLQuery} from '@aws-amplify/api';
import {generateClient} from 'aws-amplify/api';

import {UpdateUserMutation, UpdateUserMutationVariables} from '../API';

const client = generateClient();

export type GraphQLAuthMode =
  | 'apiKey'
  | 'oidc'
  | 'userPool'
  | 'iam'
  | 'lambda'
  | 'none';

export const getAuthMode = (isAuthenticated: boolean): GraphQLAuthMode =>
  isAuthenticated ? 'userPool' : 'apiKey';

/** -------  User -------- */
export const updateUser = async (
  input: UpdateUserMutationVariables['input'],
  isAuthenticated: boolean,
) => {
  // Using a custom mutation here because the generated one has non-public activities field
  const updateUserMutation = /* GraphQL */ `
    mutation UpdateUser(
      $input: UpdateUserInput!
      $condition: ModelUserConditionInput
    ) {
      updateUser(input: $input, condition: $condition) {
        id
        phone
        name
        email
        createdAt
        updatedAt
        __typename
      }
    }
  `;
  const updateUserVariables: UpdateUserMutationVariables = {
    input,
  };
  const user = await client.graphql<GraphQLQuery<UpdateUserMutation>>({
    query: updateUserMutation,
    variables: updateUserVariables,
    authMode: getAuthMode(isAuthenticated),
  });
  return user;
};
