import React from 'react';
import ReactDOM from 'react-dom/client';
import {Authenticator, ThemeProvider} from '@aws-amplify/ui-react';
import {Amplify} from 'aws-amplify';
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  Outlet,
  redirect,
  RouterProvider,
} from 'react-router-dom';
import {Provider as BeactiveProvider} from './context';
import './index.css';
import reportWebVitals from './reportWebVitals';
import createTheme from '@mui/material/styles/createTheme';
import MaterialThemeProvider from '@mui/material/styles/ThemeProvider';
import {LocalizationProvider} from '@mui/x-date-pickers-latest';
import {AdapterDateFns} from '@mui/x-date-pickers-latest/AdapterDateFnsV3';
import amplifyConfig from './amplifyconfiguration.json';
import {PostHogProvider} from 'posthog-js/react';

import '@aws-amplify/ui-react/styles.css';
import {studioTheme} from './ui-components';
import {ErrorBoundary} from './routes/errorBoundary';
import Alert from './components/Alert';
import {isSameDay} from 'date-fns';
import {getInitData} from './lib/useInitData';
import {isAuthenticated} from './lib/auth';

Amplify.configure(amplifyConfig);
// Never make these types independent of the loader function
export type VenueContextType = Exclude<
  Awaited<ReturnType<typeof venueLoader>>,
  Response
>;
const venueLoader = async ({params, request}: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const auth = url.searchParams.get('auth');
  const subDomain = params.subdomain;
  if (!subDomain) {
    throw new Response('No venue has been set', {status: 500});
  }
  const response = await getInitData(subDomain);
  const authenticated = await isAuthenticated(response.data.getVenue?.owner);
  if (authenticated && auth !== 'true') {
    url.searchParams.set('auth', 'true');
    return redirect(url.toString());
  }
  return {
    venue: response.data.getVenue,
    assets: response.data.getVenue?.assets?.items,
    schedules: response.data.getVenue?.schedules?.items,
    authenticated,
  };
};

if (process.env.NODE_ENV !== 'development') {
  console.log = () => null;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const router = createBrowserRouter([
  {
    path: '/',
    lazy: () => import('./routes/root'),
    errorElement: <ErrorBoundary />,
    shouldRevalidate: () => false,
  },
  {
    path: '/:subdomain',
    id: 'venue',
    element: <Outlet />,
    loader: venueLoader,
    shouldRevalidate: args => {
      const subdomain = args.nextParams.subdomain;
      if (subdomain && args.nextUrl.pathname.endsWith(subdomain)) {
        return true;
      }
      return false;
    },
    errorElement: <ErrorBoundary />,
    children: [
      {index: true, lazy: () => import('./routes/venue')},
      {
        path: 'calendar',
        lazy: () => import('./routes/calendar'),
        children: [
          // {index: true, element: <CalendarIndex />},
          {
            path: 'admin',
            id: 'calendarAdmin',
            lazy: () => import('./routes/calendarAdmin'),
            shouldRevalidate: ({currentUrl, nextUrl}) => {
              const currentSt = currentUrl.searchParams.get('st');
              const nextSt = nextUrl.searchParams.get('st');
              const result =
                !isSameDay(Number(nextSt), Number(currentSt)) ||
                (!currentUrl.pathname.endsWith('admin') &&
                  nextUrl.pathname.endsWith('admin'));
              return result;
            },
            errorElement: <ErrorBoundary />,
            children: [
              {
                path: ':id',
                lazy: () => import('./routes/calendarAdminActivity'),
              },
            ],
          },
        ],
      },
      {path: 'addons', lazy: () => import('./routes/addons')},
      {path: 'checkout', lazy: () => import('./routes/checkout')},
      {path: 'success', lazy: () => import('./routes/successPage')},
      {path: 'login', lazy: () => import('./routes/loginPage')},
      {
        path: 'settings',
        lazy: () => import('./routes/settings'),
        children: [
          {path: 'billing', lazy: () => import('./routes/settingsBilling')},
          {path: 'assets', lazy: () => import('./routes/settingsAssets')},
          {path: 'venue', lazy: () => import('./routes/settingsVenue')},
          {path: 'price', lazy: () => import('./routes/settingsPrice')},
          {path: 'addons', lazy: () => import('./routes/settingsAddons')},
        ],
      },
    ],
  },
  {path: '/error', element: <ErrorBoundary />},
  {
    path: '/cancel/:id/:paymentIntentId',
    lazy: () => import('./routes/deleteActivity'),
  },
]);

const materialTheme = createTheme({
  typography: {
    fontFamily: 'Helvetica Neue',
    body1: {
      fontSize: '1.25rem',
    },
    h4: {
      fontFamily: 'inherit',
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      main: '#00404d',
    },
    secondary: {
      main: '#72cf99',
    },
    error: {
      main: '#981d1d',
    },
  },
});

root.render(
  <MaterialThemeProvider theme={materialTheme}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={studioTheme}>
        <Authenticator.Provider>
          <BeactiveProvider>
            <React.StrictMode>
              <PostHogProvider
                apiKey="phc_4A6160wENI9v819ptZMnJqyfalGTVOSK2hF5zRKpNha"
                options={{api_host: 'https://app.posthog.com'}}>
                <RouterProvider router={router} />
                <Alert />
              </PostHogProvider>
            </React.StrictMode>
          </BeactiveProvider>
        </Authenticator.Provider>
      </ThemeProvider>
    </LocalizationProvider>
  </MaterialThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
