import React from 'react';
import {Button} from '@aws-amplify/ui-react';
import {Link, useRouteError} from 'react-router-dom';
import {usePostHog} from 'posthog-js/react';

export const ErrorBoundary: React.FC<{message?: string}> = ({message}) => {
  const error: any = useRouteError();
  const postHog = usePostHog();

  React.useEffect(() => {
    postHog.capture('error', {
      message: error?.message,
      data: error?.data,
      stack: error?.stack,
    });
  }, [postHog]);

  return (
    <>
      <div style={{textAlign: 'center', margin: '50px'}}>
        <p
          style={{
            fontSize: '40px',
            fontWeight: 'bold',
            color: 'var(--amplify-colors-brand-primary-100)',
          }}>
          Sorry, it looks like something went wrong!
        </p>
        <p style={{fontSize: '24px', marginBottom: '50px'}}>{message}</p>
        {error?.data && (
          <p style={{fontSize: '24px', marginBottom: '50px'}}>{error.data}</p>
        )}
        <Link to={`/`}>
          <Button variation="primary">Go Home</Button>
        </Link>
      </div>
    </>
  );
};
