import {fetchAuthSession} from 'aws-amplify/auth';

type AuthSession = Awaited<ReturnType<typeof fetchAuthSession>>;

export const fetchUser = async (): Promise<AuthSession> => {
  return await fetchAuthSession();
};

export const isAdmin = (user: AuthSession): boolean => {
  const groups = user?.tokens?.accessToken.payload['cognito:groups'];
  if (groups && Array.isArray(groups) && groups.includes('Admin')) {
    return true;
  }
  return false;
};

export const isAuthenticated = async (
  owner?: string | null,
): Promise<boolean> => {
  const user: AuthSession = await fetchAuthSession();
  if (!user.userSub && !owner) return false;
  else if (isAdmin(user) || user?.userSub === owner) {
    return true;
  }
  return false;
};
