/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function NavBar(props) {
  const { actionButtons, overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        badminton: {
          width: "32px",
          height: "32px",
          viewBox: { minX: 0, minY: 0, width: 32, height: 32 },
          paths: [
            {
              d: "M3.74092 20.1192L1.74243 22.1175C-0.522956 24.3818 -0.587298 27.9914 1.59583 30.3346C1.62109 30.3619 1.64739 30.3881 1.67453 30.4134C2.77317 31.4366 4.20578 32 5.7087 32C7.28938 32 8.77554 31.3847 9.89325 30.267L11.8925 28.2681L23.1597 23.6618C23.1645 23.6599 23.1693 23.6579 23.1742 23.656L29.1791 21.201C31.1109 20.4117 31.7402 19.1341 31.9279 18.2016C32.2273 16.7146 31.5825 15.0035 30.2032 13.6245C29.0621 12.4837 27.4534 11.9085 25.7673 11.9342C25.8933 10.456 25.4163 8.93512 24.3286 7.75811C24.3005 7.72783 24.2712 7.6986 24.2408 7.67031C23.0634 6.58266 21.542 6.10647 20.0637 6.23178C20.0903 4.54473 19.514 2.93741 18.373 1.79654C17.2164 0.639847 15.8265 0 14.5308 0C14.2813 0 14.0353 0.0237834 13.7952 0.0720836C12.8624 0.259941 11.5846 0.888997 10.7948 2.82153L8.34443 8.83035C8.34264 8.83444 8.34107 8.83852 8.3394 8.84261L3.74092 20.1192ZM11.6737 24.9617L18.1419 19.9929L19.792 21.6427L11.6737 24.9617ZM9.19544 22.9016L9.0939 22.8001L14.2453 16.0969L15.9 17.7513L9.19544 22.9016ZM7.07508 20.2682L10.3602 12.2123L12.0033 13.8554L7.07508 20.2682ZM7.67009 28.0443C7.14624 28.5682 6.44958 28.8567 5.7086 28.8567C5.02232 28.8567 4.36747 28.6064 3.85839 28.151C2.87366 27.0519 2.91631 25.3886 3.96528 24.3401L5.0767 23.229L8.78162 26.9332L7.67009 28.0443ZM27.9801 15.8473C28.675 16.5418 28.916 17.2329 28.8458 17.5812C28.7771 17.9227 28.2799 18.1726 27.9893 18.2914L22.9476 20.3525L20.6562 18.0617L23.9043 15.5664C25.368 14.8237 27.0675 14.9348 27.9801 15.8473ZM22.0609 9.93748C22.8532 10.8405 22.8269 12.1866 22.0048 13.0618L18.4142 15.8201L16.1769 13.5832L18.9356 9.99322C19.8111 9.17149 21.1576 9.14551 22.0609 9.93748ZM13.7054 4.00944C13.8239 3.71922 14.0739 3.22218 14.4155 3.15345C14.7632 3.08346 15.455 3.32412 16.1496 4.01887C17.0624 4.93155 17.1737 6.63086 16.4307 8.09411L13.9351 11.3416L11.6479 9.05488L13.7054 4.00944Z",
              fill: "rgba(115,207,153,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Title: { fontSize: "26px", lineHeight: "30.46875px" },
        Logo: {},
        MyIcon: { width: "24px", height: "24px", shrink: "0" },
        ActionButtons: { width: "unset", height: "unset" },
        NavBar: { padding: "10px 16px 10px 16px" },
      },
      variantValues: { breakpoint: "base" },
    },
    {
      overrides: {
        badminton: {},
        Title: {},
        Logo: {},
        MyIcon: {},
        ActionButtons: {},
        NavBar: {},
      },
      variantValues: { breakpoint: "medium" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="row"
      width="900px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      padding="20px 32px 20px 32px"
      backgroundColor="rgba(0,85,102,1)"
      display="flex"
      {...getOverrideProps(overrides, "NavBar")}
      {...rest}
    >
      <Flex
        gap="15px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Logo")}
      >
        <Icon
          width="40px"
          height="40px"
          viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
          paths={[
            {
              d: "M4.67615 25.149L2.17804 27.6469C-0.653695 30.4772 -0.734123 34.9892 1.99479 37.9183C2.02636 37.9524 2.05924 37.9851 2.09316 38.0168C3.46646 39.2958 5.25722 40 7.13588 40C9.11173 40 10.9694 39.2308 12.3666 37.8337L14.8656 35.3351L28.9496 29.5772C28.9556 29.5749 28.9617 29.5724 28.9677 29.57L36.4739 26.5012C38.8886 25.5146 39.6752 23.9176 39.9099 22.7521C40.2842 20.8933 39.4782 18.7543 37.754 17.0307C36.3276 15.6046 34.3168 14.8856 32.2092 14.9178C32.3666 13.07 31.7703 11.1689 30.4108 9.69763C30.3756 9.65978 30.339 9.62324 30.301 9.58788C28.8292 8.22833 26.9275 7.63309 25.0797 7.78972C25.1129 5.68091 24.3925 3.67176 22.9663 2.24567C21.5205 0.799809 19.7831 0 18.1635 0C17.8516 0 17.5441 0.0297293 17.244 0.0901045C16.078 0.324926 14.4807 1.11125 13.4935 3.52691L10.4305 11.0379C10.4283 11.043 10.4263 11.0482 10.4242 11.0533L4.67615 25.149ZM14.5921 31.2021L22.6774 24.9912L24.74 27.0534L14.5921 31.2021ZM11.4943 28.6271L11.3674 28.5001L17.8066 20.1211L19.875 22.1892L11.4943 28.6271ZM8.84385 25.3352L12.9503 15.2654L15.0042 17.3192L8.84385 25.3352ZM9.58761 35.0554C8.9328 35.7102 8.06198 36.0709 7.13575 36.0709C6.27789 36.0709 5.45934 35.758 4.82299 35.1887C3.59208 33.8149 3.64539 31.7358 4.9566 30.4251L6.34588 29.0362L10.977 33.6665L9.58761 35.0554ZM34.9752 19.8091C35.8437 20.6773 36.145 21.5411 36.0573 21.9765C35.9713 22.4034 35.3499 22.7158 34.9867 22.8643L28.6845 25.4407L25.8203 22.5771L29.8804 19.458C31.71 18.5296 33.8344 18.6685 34.9752 19.8091ZM27.5762 12.4219C28.5665 13.5506 28.5336 15.2333 27.506 16.3273L23.0177 19.7751L20.2211 16.9789L23.6695 12.4915C24.7638 11.4644 26.4471 11.4319 27.5762 12.4219ZM17.1317 5.0118C17.2799 4.64903 17.5924 4.02773 18.0194 3.94181C18.4541 3.85433 19.3187 4.15516 20.187 5.02359C21.328 6.16443 21.4671 8.28857 20.5384 10.1176L17.4188 14.1771L14.5599 11.3186L17.1317 5.0118Z",
              fill: "rgba(115,207,153,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "badminton")}
        ></Icon>
        <Text
          fontFamily="Roboto"
          fontSize="32px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          textTransform="capitalize"
          lineHeight="37.5px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Apex Badminton"
          {...getOverrideProps(overrides, "Title")}
        ></Text>
      </Flex>
      <Flex
        gap="32px"
        direction="row"
        width="32px"
        height="32px"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        children={actionButtons}
        {...getOverrideProps(overrides, "ActionButtons")}
      ></Flex>
    </Flex>
  );
}
