/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  CheckboxField,
  Flex,
  Heading,
  StepperField,
  Text,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function AddOn(props) {
  const {
    title,
    description,
    selected,
    step,
    price,
    overrides: overridesProp,
    ...restProp
  } = props;
  const variants = [
    {
      overrides: {
        "100": {},
        CheckboxField: {},
        Heading: {},
        $: {},
        "/hr": {},
        Price: {},
        HeadingPrice: {},
        description: {},
        Description: {},
        StepperField: {
          width: "unset",
          padding: "0px 10px 0px 0px",
          alignSelf: "stretch",
        },
        Content: {
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "5px 10px 5px 10px",
        },
        AddOn: { width: "302px" },
      },
      variantValues: { breakpoint: "base", variant: "checked" },
    },
    {
      overrides: {
        "100": {},
        CheckboxField: {},
        Heading: {},
        $: {},
        "/hr": {},
        Price: {},
        HeadingPrice: {},
        description: {},
        Description: {},
        StepperField: {
          width: "unset",
          padding: "0px 10px 0px 0px",
          alignSelf: "stretch",
        },
        Content: {
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "5px 10px 5px 10px",
        },
        AddOn: {
          width: "302px",
          border: "1px SOLID rgba(0,85,102,1)",
          backgroundColor: "rgba(255,255,255,1)",
        },
      },
      variantValues: { breakpoint: "base", variant: "default" },
    },
    {
      overrides: {
        "100": {},
        CheckboxField: {},
        Heading: {},
        $: {},
        "/hr": {},
        Price: {},
        HeadingPrice: {},
        description: {},
        Description: {},
        StepperField: {},
        Content: {},
        AddOn: {
          border: "1px SOLID rgba(0,85,102,1)",
          backgroundColor: "rgba(255,255,255,1)",
        },
      },
      variantValues: { breakpoint: "medium", variant: "default" },
    },
    {
      overrides: {
        "100": {},
        CheckboxField: {},
        Heading: {},
        $: {},
        "/hr": {},
        Price: {},
        HeadingPrice: {},
        description: {},
        Description: {},
        StepperField: {},
        Content: {},
        AddOn: {},
      },
      variantValues: { breakpoint: "medium", variant: "checked" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    base: "base",
    medium: "medium",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="473px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      border="1px SOLID rgba(58,105,78,1)"
      borderRadius="6px"
      padding="5px 0px 5px 0px"
      backgroundColor="rgba(214,245,219,1)"
      display="flex"
      {...getOverrideProps(overrides, "AddOn")}
      {...rest}
    >
      <CheckboxField
        width="24px"
        height="unset"
        gap="0"
        padding="0px 0px 0px 10px"
        shrink="0"
        size="default"
        defaultChecked={true}
        isDisabled={false}
        labelPosition="end"
        checked={selected}
        {...getOverrideProps(overrides, "CheckboxField")}
      ></CheckboxField>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="10px 10px 10px 10px"
        display="flex"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="20px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 10px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "HeadingPrice")}
        >
          <Heading
            width="unset"
            height="unset"
            grow="1"
            shrink="1"
            basis="0"
            level="6"
            children={title}
            {...getOverrideProps(overrides, "Heading")}
          ></Heading>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Price")}
          >
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="700"
              color="rgba(0,64,77,1)"
              lineHeight="25px"
              textAlign="right"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="$"
              {...getOverrideProps(overrides, "$")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="700"
              color="rgba(0,64,77,1)"
              lineHeight="25px"
              textAlign="right"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={price}
              {...getOverrideProps(overrides, "100")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="700"
              color="rgba(0,64,77,1)"
              lineHeight="25px"
              textAlign="right"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="/hr"
              {...getOverrideProps(overrides, "/hr")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 100px 20px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Description")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            color="rgba(92,102,112,1)"
            lineHeight="21px"
            textAlign="justify"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={description}
            {...getOverrideProps(overrides, "description")}
          ></Text>
        </Flex>
        <StepperField
          width="266px"
          height="unset"
          gap="0"
          padding="0px 24px 0px 0px"
          shrink="0"
          size="default"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          value={step}
          {...getOverrideProps(overrides, "StepperField")}
        ></StepperField>
      </Flex>
    </Flex>
  );
}
