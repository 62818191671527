import React, {ReactNode, createContext, useReducer} from 'react';
import {getActivitiesFromResponse} from './lib/useActivities';
import useInitData, {getVenueFromResponse} from './lib/useInitData';
import {fetchAuthSession} from 'aws-amplify/auth';
import {useAuthenticator} from '@aws-amplify/ui-react';
import getSubdomain from './lib/getSubdomain';
import {isAuthenticated, isAdmin} from './lib/auth';
import {DEFAULT_PLATFORM_FEE} from './lib/price';

export enum AlertSeverity {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export type Cell = {
  price: number | null | undefined;
  blocked: boolean | null | undefined;
  selected: boolean;
  highlighted: boolean;
};

type CellSelection = {
  id: number;
  hour: number;
  price: number | null | undefined;
};

type AddOnSelection = {
  title: string;
  count: number;
  price: number;
};

type Price = {
  booking: number;
  addOn: number;
  fee: number;
  tax: number;
  total: number;
};

type Alert = {
  severity: AlertSeverity;
  message?: ReactNode;
  autoHideDuration?: number;
  icon?: ReactNode;
  location?: 'top' | 'bottom';
};

type Auth = {
  isAuthenticated: boolean;
  isAdmin: boolean;
  username?: string;
};

export type State = {
  venue?: NonNullable<Awaited<ReturnType<typeof getVenueFromResponse>>>;
  assets?: NonNullable<
    NonNullable<Awaited<ReturnType<typeof getVenueFromResponse>>>['assets']
  >['items'];
  schedules?: NonNullable<
    NonNullable<Awaited<ReturnType<typeof getVenueFromResponse>>>['schedules']
  >['items'];
  activities?: Awaited<ReturnType<typeof getActivitiesFromResponse>>;
  currentDate: Date;
  datePickerOpen: boolean;
  price: Price;
  cellSelection: (CellSelection | undefined)[];
  addOnSelection: (AddOnSelection | undefined)[];
  auth: Auth;
  alert?: Alert;
  loading: boolean;
};

type Action =
  | {
      type: 'venue';
      payload: NonNullable<Awaited<ReturnType<typeof getVenueFromResponse>>>;
    }
  | {
      type: 'assets';
      payload?: NonNullable<
        NonNullable<Awaited<ReturnType<typeof getVenueFromResponse>>>['assets']
      >['items'];
    }
  | {
      type: 'schedules';
      payload?: NonNullable<
        NonNullable<
          Awaited<ReturnType<typeof getVenueFromResponse>>
        >['schedules']
      >['items'];
    }
  | {
      type: 'activities';
      payload: Awaited<ReturnType<typeof getActivitiesFromResponse>>;
    }
  | {
      type: 'currentDate';
      payload: Date;
    }
  | {
      type: 'datePickerOpen';
      payload: boolean;
    }
  | {
      type: 'cellSelection';
      payload: CellSelection[];
    }
  | {
      type: 'addOnSelection';
      payload: AddOnSelection[];
    }
  | {
      type: 'auth';
      payload: Auth;
    }
  | {
      type: 'loading';
      payload: boolean;
    }
  | {
      type: 'alert';
      payload: Alert | undefined;
    }
  | {
      type: 'resetUI';
    };

export type Dispatch = (action: Action) => void;

const initialState: State = {
  venue: undefined,
  assets: undefined,
  schedules: undefined,
  activities: undefined,
  price: {
    booking: 0,
    addOn: 0,
    fee: 0,
    tax: 0,
    total: 0,
  },
  cellSelection: [],
  addOnSelection: [],
  currentDate: new Date(),
  datePickerOpen: false,
  auth: {
    isAuthenticated: false,
    isAdmin: false,
  },
  alert: undefined,
  loading: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'resetUI':
      return {
        ...state,
        price: initialState.price,
        cellSelection: initialState.cellSelection,
        addOnSelection: initialState.addOnSelection,
        activities: initialState.activities,
      };
    case 'venue':
      if (action.payload.name) {
        document.title = action.payload.name;
      }
      return {...state, venue: action.payload};
    case 'assets':
      return {...state, assets: action.payload};
    case 'schedules':
      return {...state, schedules: action.payload};
    case 'activities':
      return {...state, activities: action.payload};
    case 'currentDate':
      return {...state, currentDate: action.payload};
    case 'datePickerOpen':
      return {...state, datePickerOpen: action.payload};
    case 'cellSelection': {
      const booking = action.payload.reduce(
        (acc, curr) => (curr.price ? acc + curr.price : acc),
        0,
      );
      const venueFee: number = state?.venue?.fee || DEFAULT_PLATFORM_FEE;
      const fee = parseFloat((booking * venueFee).toFixed(2));
      const tax = parseFloat((booking * 0.05).toFixed(2));
      const total = booking + fee + tax;
      return {
        ...state,
        cellSelection: action.payload,
        price: {
          ...state.price,
          booking,
          fee,
          tax,
          total,
        },
      };
    }
    case 'addOnSelection': {
      console.log('addOnSelection reducer', action.payload);
      const totalHours = state.cellSelection.length;
      const bookingAmount = state.cellSelection.reduce(
        (acc, curr) => (curr?.price ? acc + curr.price : acc),
        0,
      );
      const addOnAmount = action.payload.reduce(
        (acc, curr) => acc + curr.price * totalHours * curr.count,
        0,
      );
      const venueFee: number = state?.venue?.fee || DEFAULT_PLATFORM_FEE;
      const fee = Math.round((bookingAmount + addOnAmount) * venueFee);
      const tax = Math.round((bookingAmount + addOnAmount + fee) * 0.05);
      const total = bookingAmount + addOnAmount + fee + tax;
      return {
        ...state,
        addOnSelection: action.payload,
        price: {
          ...state.price,
          addOn: addOnAmount,
          fee,
          tax,
          total,
        },
      };
    }
    case 'auth':
      return {
        ...state,
        auth: action.payload,
      };
    case 'alert':
      return {...state, alert: action.payload};
    case 'loading':
      return {...state, loading: action.payload};
    default:
      throw new Error(`Unhandled action: ${action}`);
  }
};

const Context = createContext<{
  state: State;
  dispatch: Dispatch;
  refetch: () => void;
}>({
  state: initialState,
  dispatch: () => null,
  refetch: () => null,
});

const Provider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // const {refetch} = useInitData(getSubdomain(), state, dispatch);

  // const {user} = useAuthenticator(context => [context.user]);

  // React.useEffect(() => {
  //   const fetchAuth = async () => {
  //     const authSession = await fetchAuthSession();
  //     dispatch({
  //       type: 'auth',
  //       payload: {
  //         isAuthenticated: isAuthenticated(
  //           authSession,
  //           state.venue?.owner || user?.username,
  //         ),
  //         username: user?.username,
  //         isAdmin: isAdmin(authSession),
  //       },
  //     });
  //   };
  //   fetchAuth();
  // }, [state.venue, user]);

  return (
    <Context.Provider value={{state, dispatch, refetch: () => null}}>
      {children}
    </Context.Provider>
  );
};

export {Context, Provider};
