import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {Context} from '../context';
import Slide from '@mui/material/Slide';

export enum ALERT_MESSAGE {
  'success' = 'Saved',
  'error' = 'Something went wrong. Please contact support.',
  'info' = 'Info',
  'warning' = 'Something went wrong. Please contact support.',
}

export default () => {
  const {
    state: {alert},
    dispatch,
  } = React.useContext(Context);
  return (
    <>
      <Snackbar
        open={alert !== undefined}
        onClose={() => dispatch({type: 'alert', payload: undefined})}
        TransitionComponent={Slide}
        anchorOrigin={{
          vertical: alert?.location || 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={alert?.autoHideDuration || 3000}>
        <Alert
          icon={alert ? alert.icon : false}
          severity={alert?.severity}
          sx={{width: '100%', justifyContent: 'center'}}>
          {alert?.message || (alert && ALERT_MESSAGE[alert.severity])}
        </Alert>
      </Snackbar>
    </>
  );
};
