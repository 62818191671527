/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Divider, Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function Checkout(props) {
  const {
    feeTaxAmount,
    totalAmount,
    disabled,
    addOnAmount,
    bookingAmount,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="351px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
      borderRadius="8px"
      padding="20px 20px 20px 20px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "Checkout")}
      {...rest}
    >
      <Flex
        gap="15px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 422")}
      >
        <Icon
          width="28px"
          height="28px"
          viewBox={{ minX: 0, minY: 0, width: 28, height: 28 }}
          paths={[
            {
              d: "M3.27331 17.6043L1.52463 19.3528C-0.457587 21.334 -0.513886 24.4925 1.39635 26.5428C1.41845 26.5666 1.44146 26.5896 1.46521 26.6117C2.42652 27.5071 3.68005 28 4.99511 28C6.37821 28 7.6786 27.4616 8.65659 26.4836L10.4059 24.7346L20.2647 20.704C20.2689 20.7024 20.2732 20.7007 20.2774 20.699L25.5318 18.5508C27.222 17.8603 27.7726 16.7424 27.9369 15.9264C28.1989 14.6253 27.6347 13.128 26.4278 11.9215C25.4293 10.9232 24.0218 10.4199 22.5464 10.4425C22.6566 9.149 22.2392 7.81823 21.2876 6.78834C21.2629 6.76185 21.2373 6.73627 21.2107 6.71152C20.1805 5.75983 18.8493 5.34316 17.5558 5.4528C17.579 3.97664 17.0747 2.57023 16.0764 1.57197C15.0644 0.559866 13.8482 0 12.7145 0C12.4961 0 12.2808 0.0208105 12.0708 0.0630732C11.2546 0.227448 10.1365 0.777872 9.44543 2.46884L7.30137 7.72656C7.29981 7.73013 7.29844 7.73371 7.29697 7.73728L3.27331 17.6043ZM10.2145 21.8415L15.8742 17.4938L17.318 18.9374L10.2145 21.8415ZM8.04601 20.0389L7.95716 19.9501L12.4646 14.0848L13.9125 15.5324L8.04601 20.0389ZM6.1907 17.7347L9.06518 10.6858L10.5029 12.1234L6.1907 17.7347ZM6.71133 24.5388C6.25296 24.9971 5.64338 25.2496 4.99502 25.2496C4.39453 25.2496 3.82154 25.0306 3.37609 24.6321C2.51445 23.6704 2.55177 22.2151 3.46962 21.2976L4.44211 20.3253L7.68392 23.5665L6.71133 24.5388ZM24.4826 13.8664C25.0906 14.4741 25.3015 15.0788 25.2401 15.3835C25.1799 15.6824 24.7449 15.901 24.4907 16.005L20.0791 17.8085L18.0742 15.804L20.9163 13.6206C22.197 12.9707 23.684 13.068 24.4826 13.8664ZM19.3033 8.6953C19.9965 9.48545 19.9735 10.6633 19.2542 11.4291L16.1124 13.8425L14.1548 11.8853L16.5687 8.74407C17.3347 8.02505 18.5129 8.00232 19.3033 8.6953ZM11.9922 3.50826C12.0959 3.25432 12.3147 2.81941 12.6136 2.75927C12.9178 2.69803 13.5231 2.90861 14.1309 3.51651C14.9296 4.3151 15.027 5.802 14.3769 7.08235L12.1932 9.92394L10.1919 7.92302L11.9922 3.50826Z",
              fill: "rgba(89,166,121,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "badminton")}
        ></Icon>
        <Text
          fontFamily="Inter"
          fontSize="22px"
          fontWeight="800"
          color="rgba(0,85,102,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.07px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Booking Details"
          {...getOverrideProps(overrides, "Booking Details")}
        ></Text>
        <Icon
          width="16px"
          height="20px"
          viewBox={{ minX: 0, minY: 0, width: 16, height: 20 }}
          paths={[
            {
              d: "M14 4L12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4L2 4C0.9 4 0 4.9 0 6L0 18C0 19.1 0.9 20 2 20L14 20C15.1 20 16 19.1 16 18L16 6C16 4.9 15.1 4 14 4ZM8 2C9.1 2 10 2.9 10 4L6 4C6 2.9 6.9 2 8 2ZM14 18L2 18L2 6L4 6L4 8C4 8.55 4.45 9 5 9C5.55 9 6 8.55 6 8L6 6L10 6L10 8C10 8.55 10.45 9 11 9C11.55 9 12 8.55 12 8L12 6L14 6L14 18Z",
              fill: "rgba(89,166,121,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Vector")}
        ></Icon>
      </Flex>
      <Divider
        width="unset"
        height="1px"
        shrink="0"
        alignSelf="stretch"
        size="small"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider38802970")}
      ></Divider>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="5px 0px 5px 0px"
        {...getOverrideProps(overrides, "Frame 423")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="300"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Booking"
          {...getOverrideProps(overrides, "Booking")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="800"
          color="rgba(0,64,77,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={`${"$"}${bookingAmount}`}
          {...getOverrideProps(overrides, "Booking Amount")}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="5px 0px 5px 0px"
        {...getOverrideProps(overrides, "Frame 424")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="300"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Add Ons"
          {...getOverrideProps(overrides, "Add Ons")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="800"
          color="rgba(0,64,77,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={`${"$"}${addOnAmount}`}
          {...getOverrideProps(overrides, "Add On Amount")}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="5px 0px 5px 0px"
        {...getOverrideProps(overrides, "Frame 425")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="300"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Fees & Estimated Tax"
          {...getOverrideProps(overrides, "Fee and Tax")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="800"
          color="rgba(0,64,77,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={`${"$"}${feeTaxAmount}`}
          {...getOverrideProps(overrides, "Fee Tax Amount")}
        ></Text>
      </Flex>
      <Divider
        width="unset"
        height="1px"
        shrink="0"
        alignSelf="stretch"
        size="small"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider38802984")}
      ></Divider>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="5px 0px 5px 0px"
        {...getOverrideProps(overrides, "Frame 427")}
      >
        <Text
          fontFamily="Inter"
          fontSize="22px"
          fontWeight="800"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Total"
          {...getOverrideProps(overrides, "Total")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="22px"
          fontWeight="800"
          color="rgba(0,0,0,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={`${"$"}${totalAmount}`}
          {...getOverrideProps(overrides, "Total Amount")}
        ></Text>
      </Flex>
      <Divider
        width="unset"
        height="1px"
        shrink="0"
        alignSelf="stretch"
        size="small"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider38802989")}
      ></Divider>
      <Button
        width="unset"
        height="unset"
        shrink="0"
        alignSelf="stretch"
        size="default"
        isDisabled={disabled}
        variation="primary"
        children="Book (unpaid)"
        {...getOverrideProps(overrides, "Book Unpaid")}
      ></Button>
      <Button
        width="unset"
        height="unset"
        shrink="0"
        alignSelf="stretch"
        backgroundColor="rgba(89,166,121,1)"
        size="default"
        isDisabled={disabled}
        variation="primary"
        children="Checkout"
        {...getOverrideProps(overrides, "Checkout38802991")}
      ></Button>
    </Flex>
  );
}
