/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
export default function Success(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="45px"
      direction="column"
      width="907px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="45px 0px 45px 0px"
      backgroundColor="rgba(0,0,0,0)"
      {...getOverrideProps(overrides, "Success")}
      {...rest}
    >
      <Text
        fontFamily="Inter"
        fontSize="50px"
        fontWeight="800"
        color="rgba(89,166,121,1)"
        lineHeight="60.5113639831543px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Your booking is confirmed"
        {...getOverrideProps(overrides, "Your booking is confirmed")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="30px"
        fontWeight="600"
        color="rgba(0,64,77,1)"
        lineHeight="36.30681610107422px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="You’ll receive a notification with the booking details for your reference."
        {...getOverrideProps(
          overrides,
          "You\u2019ll receive a notification with the booking details for your reference."
        )}
      ></Text>
      <Button
        width="unset"
        height="unset"
        shrink="0"
        size="large"
        isDisabled={false}
        variation="primary"
        children="Return to Calendar"
        {...getOverrideProps(overrides, "Button")}
      ></Button>
    </Flex>
  );
}
